/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

// material-ui
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material'

// project imports
import useSetState from 'hooks/useSetState'
import { formatToDollar, parseValueByType } from 'utils/utilities'

// ==============================|| RADIO COMPONENT ||============================== //

const defaultState = {
  contentOnChange: '',
}

const RadioComponent = (props) => {
  const {
    callbacks: { toggleSetValue },
    clientFlags,
    part: { detail, price_tags },
  } = props

  const [state, setState] = useSetState(defaultState)
  const { contentOnChange } = state

  useEffect(() => {
    setState({ contentOnChange: detail?.value === 0 || detail?.value ? detail?.value : '' })
  }, [detail?.value, detail])

  const updateRadioValue = (value) => {
    const updatedValue = parseValueByType(value, detail?.sub_type)

    if (updatedValue === contentOnChange) {
      setState({ contentOnChange: '' })
    } else {
      setState({ contentOnChange: updatedValue })
    }

    toggleSetValue(updatedValue === contentOnChange ? '' : updatedValue)
  }

  return (
    <Box noValidate>
      <FormControl fullWidth>
        <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group" value={contentOnChange}>
          {detail?.options?.map((option, index) => {
            const price = price_tags?.find((price) => price?.option_index === index)

            return (
              <Stack
                key={option.value}
                direction="row"
                sx={{ alignItems: 'center', justifyContent: 'space-between', width: '100%' }}
                spacing={1}
              >
                <FormControlLabel
                  value={option?.value?.toString()}
                  control={<Radio onClick={(e) => updateRadioValue(e.target.value)} />}
                  label={option?.name}
                />
                {!clientFlags.includes('HidePrice') && price && (
                  <Typography textAlign="end" sx={{ paddingRight: '13px' }}>
                    {formatToDollar(price.unit_price)}
                  </Typography>
                )}
              </Stack>
            )
          })}
        </RadioGroup>
      </FormControl>
    </Box>
  )
}

RadioComponent.propTypes = {
  callbacks: PropTypes.object,
  clientFlags: PropTypes.array,
  part: PropTypes.object,
}

export default RadioComponent
