import { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

// msal
import { useMsal } from '@azure/msal-react'

// material-ui
import { useTheme } from '@mui/material/styles'
import {
  Autocomplete,
  Avatar,
  Box,
  Chip,
  ClickAwayListener,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  TextField,
  Typography,
} from '@mui/material'

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar'

// project imports
import MainCard from 'ui-component/cards/MainCard'
import Transitions from 'ui-component/extended/Transitions'

// assets
import { IconLogout, IconSettings } from '@tabler/icons'

import useCurrentUser from 'hooks/useCurrentUser'
import useUser from 'hooks/useUser'

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = (props) => {
  const theme = useTheme()
  const { office, toggleOffice } = props

  const navigate = useNavigate()
  const { id: projectId } = useParams()

  const customization = useSelector((state) => state.customization)

  const [selectedIndex] = useState(-1)
  const [open, setOpen] = useState(false)

  const { instance } = useMsal()
  const { currentUser } = useCurrentUser()
  const { user } = useUser({ id: currentUser?.user })

  const { userRoles, offices } = useSelector((reduxState) => reduxState.entities)
  const { loading: loadingOffices } = useSelector((reduxState) => reduxState.offices)
  const { loading: loadingProjects } = useSelector((reduxState) => reduxState.projects)

  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null)
  const handleLogout = async () => {
    console.log('Logout')
    instance.logoutRedirect()
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleSetOffice = (value) => {
    if (value?.id) {
      toggleOffice(value.id)
    } else toggleOffice('')

    if (projectId && value?.id !== office) {
      navigate('/')
    }
  }

  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <>
      <Chip
        sx={{
          height: '48px',
          alignItems: 'center',
          borderRadius: '27px',
          transition: 'all .2s ease-in-out',
          borderColor: theme.palette.primary.light,
          backgroundColor: theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            '& svg': {
              stroke: theme.palette.primary.light,
            },
          },
          '& .MuiChip-label': {
            lineHeight: 0,
          },
        }}
        icon={
          <Avatar
            // src={User1}
            sx={{
              ...theme.typography.mediumAvatar,
              margin: '8px 0 8px 8px !important',
              cursor: 'pointer',
            }}
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            color="inherit"
          />
        }
        label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                  <Box sx={{ p: 2 }}>
                    <Stack>
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="h4">Hello,</Typography>
                        <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                          {user?.name || currentUser?.details?.name}
                        </Typography>
                      </Stack>
                      <Typography variant="subtitle2">
                        {user?.annotation?.flags?.realhub_reference
                          ? 'Realhub '
                          : user?.annotation?.flags?.campaigntrack_reference
                          ? 'Campaigntrack '
                          : null}
                        {Object.values(userRoles)
                          .filter((x) => currentUser?.roles.includes(x.value))
                          .map((y, index) => (index ? ' | ' : '') + y.name)}
                      </Typography>
                      {currentUser?.roles?.includes('Agent') ? null : (
                        <Autocomplete
                          getOptionLabel={(option) => (option ? option.name : '')}
                          loading={loadingOffices}
                          disabled={loadingOffices || loadingProjects}
                          noOptionsText="Loading..."
                          options={Object.values(offices)?.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1))}
                          renderOption={(props, option) => (
                            <li {...props} key={option.id}>
                              {option ? option.name : ''}
                            </li>
                          )}
                          renderInput={(params) => <TextField {...params} label="Office" variant="outlined" size="small" />}
                          value={Object.values(offices).find((x) => x.id === office) || ''}
                          onChange={(e, value) => handleSetOffice(value)}
                          slotProps={{
                            paper: {
                              sx: {
                                boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)',
                              },
                            },
                          }}
                          sx={{ marginTop: 1.5 }}
                        />
                      )}
                    </Stack>
                  </Box>

                  <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}>
                    <Divider />
                    <Box sx={{ p: 2, pt: 1 }}>
                      <List
                        component="nav"
                        sx={{
                          width: '100%',
                          maxWidth: 350,
                          minWidth: 300,
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: '10px',
                          [theme.breakpoints.down('md')]: {
                            minWidth: '100%',
                          },
                          '& .MuiListItemButton-root': {
                            mt: 0.5,
                          },
                        }}
                      >
                        <ListItemButton
                          sx={{ borderRadius: `${customization.borderRadius}px` }}
                          selected={selectedIndex === 4}
                          onClick={handleLogout}
                        >
                          <ListItemIcon>
                            <IconLogout stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                        </ListItemButton>
                      </List>
                    </Box>
                  </PerfectScrollbar>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  )
}

ProfileSection.propTypes = {
  office: PropTypes.string,
  toggleOffice: PropTypes.func,
}

export default ProfileSection
