// material-ui
import PropTypes from 'prop-types'

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import { Box, Button, Tooltip, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import AvatarButton from 'ui-component/AvatarButton'
import ModalContext from 'contexts/modalContext'
import ProjectModal from 'modals/ProjectModal'

import useCurrentUser from 'hooks/useCurrentUser'
import useModals from 'hooks/useModals'
import useSetState from 'hooks/useSetState'
import useUser from 'hooks/useUser'

// ==============================|| ACTION ||============================== //

const callbacks = (component, setState) => {
  const componentCallbacks = {
    ProjectModal: {
      closeModal: () => setState({ showProjectModal: false }),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  showProjectModal: false,
}

const ActionSection = (props) => {
  const theme = useTheme()
  const { office } = props

  const { currentUser } = useCurrentUser()

  const { user } = useUser({ id: currentUser?.user })

  const [state, setState] = useSetState(defaultState)
  const { showProjectModal } = state

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const showModal = (payload) => {
    const updatedPayload = {
      ...payload,
      ...(office && { office: office }),
    }
    setModalData('ProjectModal', updatedPayload)
    setState({ showProjectModal: true })
  }

  return (
    <ModalContext.Provider value={modalContext}>
      {user?.username && !user?.annotation?.flags?.realhub_reference && !user?.annotation?.flags?.campaigntrack_reference ? (
        <Box sx={{ ml: 2 }}>
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <Tooltip title="New Project" arrow>
              <AvatarButton callbacks={{ toggleClick: () => showModal() }}>
                <AddCircleOutlineOutlinedIcon fontSize="inherit" />
              </AvatarButton>
            </Tooltip>
          </Box>
          <Button
            variant="contained"
            disableElevation
            disabled={!currentUser?.roles?.includes('Agent') && !office}
            endIcon={<AddCircleOutlineOutlinedIcon />}
            onClick={() => showModal()}
            sx={{
              display: { xs: 'none', md: 'flex' },
              lineHeight: 1,
              height: 34,
              transition: 'all .2s ease-in-out',
              background: theme.palette.primary.light,
              color: theme.palette.primary.dark,
              '&[aria-controls="menu-list-grow"],&:hover': {
                background: theme.palette.primary.dark,
                color: theme.palette.primary.light,
              },
            }}
          >
            <Typography variant="buttonText">New Project</Typography>
          </Button>
          <ProjectModal callbacks={callbacks('ProjectModal', setState)} showModal={showProjectModal} />
        </Box>
      ) : null}
    </ModalContext.Provider>
  )
}

ActionSection.propTypes = {
  office: PropTypes.string,
}

export default ActionSection
